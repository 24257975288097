// Import bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
// import axios
import axios from 'axios';
// Import swal
import Swal from 'sweetalert2'
// Import useState
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { BlobServiceClient } from '@azure/storage-blob';
const Admin = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showVideo, setShowVideo] = useState(false);
    const handleCloseVideo = () => setShowVideo(false);
    const handleShowVideo = () => setShowVideo(true);
    
    const uploadFunc = async () => {
        let loadingSwal; // Variable to store loading Swal instance
    
        try {
            // Get the SAS token
            const response = await axios.get('/api/uploadvideo');
            console.log(response.data);
    
            // Correct SAS token concatenation
            const blobServiceClient = new BlobServiceClient(`https://praisealongprivate.blob.core.windows.net?${response.data}`);
            const containerClient = blobServiceClient.getContainerClient("praisealongprivate");
    
            // Check if selectedFile is defined and has a value
            if (!selectedFile) {
                console.error("No file selected");
                return;
            }
    
            // Make blob name dynamic based on the selected file
            const blobName = "currentVideo.mp4";
            const fileContent = await selectedFile.arrayBuffer();
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    
            // Show loading Swal
            loadingSwal = Swal.fire({
                title: 'Uploading...',
                html: 'Please wait while the file is being uploaded.',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading();
                }
            });
    
            // Upload the file content
            await blockBlobClient.upload(fileContent, fileContent.byteLength);
    
            // Close loading Swal after successful upload
            loadingSwal.close();
    
            // Show success Swal
            Swal.fire(
                'Video Uploaded',
                'Thank you for uploading the video!',
                'success'
            );
    
        } catch (error) {
            // Close loading Swal in case of error
            if (loadingSwal) {
                loadingSwal.close();
            }
    
            // Show error Swal
            Swal.fire(
                'Error',
                'There was an error uploading the video, please contact 07385 587947.',
                'error'
            );
        }
    };
    
    
      
    const formSubmitted = (e) => {

        console.log("Form Submitted");
        // Get the values from the form
        const schoolName = document.getElementById("schoolName").value;
        const dioceseName = document.getElementById("dioceseName").value;
        const emailName = document.getElementById("emailName").value;

        console.log(schoolName);
        console.log(dioceseName);
        console.log(emailName);

    //    Axios post request to /api/addSchool
       axios.post('/api/addSchool', {
        schoolName: schoolName,
        dioceseName: dioceseName,
        emailName: emailName
      })
      .then(function (response) {
        console.log(response);
        Swal.fire(
            'School Added',
            'Thank you for adding the school!, If you need a school removing at this time, please email williamfort6@icloud.com.',
            'success'
          )
      })
      .catch(function (error) {
        console.log(error);
      });

        

        e.preventDefault();
    }
    return (
        <div>
            <h1 className="m-4">Admin</h1>
            {/* Bootstrap form for submitting School name, Diocese, and Email */}
            {/* div class to give padding */}
            <div className="p-3 mb-2 bg-light text-dark m-3">
                <form>
                    <div className="form-group">
                        <label for="schoolName">School Name</label>
                        <input type="text" className="form-control" id="schoolName" aria-describedby="schoolName" placeholder="Enter School Name" />
                        <input type="text" className="form-control" id="dioceseName" aria-describedby="dioceseName" placeholder="Enter Diocese" />
                        <input type="text" className="form-control" id="emailName" aria-describedby="emailName" placeholder="Enter Email" />

                    </div>
                    <button className="btn btn-primary" onClick={formSubmitted}>Submit</button>

                </form>
            </div>
            <div className="p-3 mb-2 bg-light text-dark m-3">
                <Button variant="primary" onClick={handleShow}>
                    Make Site Changes
                </Button>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Choose your function</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Please select an option from the below to edit your site!
                        {/* Bootstrap grid with four buttons */}
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <Button variant="primary" className="m-2"onClick={() => {
                                        handleShowVideo();
                                        handleClose();
                                    }}>Change Video</Button>
                                </div>
                                <div className="col">
                                    <Button variant="primary" className="m-2">Button 2</Button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Button variant="primary" className="m-2">Button 3</Button>
                                </div>
                                <div className="col">
                                    <Button variant="primary" className="m-2">Button 4</Button>
                                </div>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showVideo} onHide={handleCloseVideo}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change your video</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <input type="file" id="fileInput" onChange={handleFileChange} />
                    <Button variant="primary" onClick={() => alert(`Selected File: ${selectedFile ? selectedFile.name : 'None'}`)}>
                        Check Selected File
                    </Button>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseVideo}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={uploadFunc}>
                            Upload
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            
        </div>
    )
}

export default Admin;