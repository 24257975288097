import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PraiseNav from './PraiseNav';
import './css/Contact.css';
import {useState , useEffect} from 'react';
import RaysOnly from '../images/RaysOnly.png';
import FooterText from './FooterText';
// Import ../images/FullLogoStrap.png as Bannerimage
import BannerImage from '../images/FullLogoStrap.png';
import MobileBannerImage from '../images/cleanLogo.png';
import FooterImage from '../images/FooterImage.png';
import Swal from 'sweetalert2';
function Contact() {
  const sendContact = async (e) => {
    // Get the name
    const name = document.querySelector('input[type="text"][id="formBasicName"]').value;
    // Get the email address
    const email = document.querySelector('input[type="email"][id="formBasicEmail"]').value;
    // Get the message
    const message = document.querySelector('textarea[id="formBasicMessage"]').value;
    // Get the checkbox
    const checkbox = document.querySelector('input[type="checkbox"][id="registerCheckbox"]').checked;

    console.log(name); 
    console.log(email);
    console.log(message);
    console.log(checkbox);

    // If checkbox is checked
    if (checkbox === true) {

          let stringtoFetch = '/api/message?message=' + message+'&email=' + email+'&name=' + name+'&requestType=contact';
          fetch(stringtoFetch)
      .then(response => response.json())
      .then(data => 
      console.log(data)
      );
      Swal.fire(
      'Message Sent',
      'Thank you for your message, we\'ll be in touch. Please make sure you check your junk email.',
      'success'
      )
    }
    else{
      Swal.fire(
        'Please agree to our privacy policy',
        'Please agree to our privacy policy',
        'error'
      )
    }

  }
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bannerImage = windowWidth < 850 ? MobileBannerImage : BannerImage;
  return (
    <>
      <div className="gradient-background"></div>
      <div className="navbar-container">
        
     <PraiseNav />

      </div>
      <a href="/" className="text-decoration-none">

      <img src={bannerImage} alt="Praise-along Logo" className="banner-image mt-5 mb-5" />
      </a>




        {/* Bootstrap form */}
        <div className="row mt-5">
            <div className="col"></div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
                {/* Form */}
                
                <span className="red-contact-text">CONTACT US</span><br />
                <span className="blue-contact-text">Please use this page for all enquiries</span>

                {/* Bootstrap form
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Enter your name" />
                    </Form.Group>
                    </Form> */}
                
            </div>
            <div className="col"></div>
        </div>

        <div className="row">
            <div className="col"></div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0 mt-3">
                {/* Form */}
                <Form>
  <Form.Group className="mb-3" controlId="formBasicName">
    <Form.Control type="text" placeholder="Your name:" className="futura-medium-font grey-text" />
  </Form.Group>
  <Form.Group className="mb-3" controlId="formBasicEmail">

    <Form.Control type="email" placeholder="Your email address:" className="mt-3 futura-medium-font grey-text" />
  </Form.Group>
  <Form.Group className="mb-3" controlId="formBasicMessage">
    <Form.Control as="textarea" placeholder="Your message to us:" className="mt-3 futura-medium-font grey-text praise-along-textarea" />
  </Form.Group>
  <Form.Group className="mb-3" controlId="formBasicRegisterCheckbox">
  <div className="checkbox-wrapper">
    <Form.Check
      type="checkbox"
      id="registerCheckbox"
    />
    <Form.Label htmlFor="registerCheckbox" className="checkbox-label">
      <span className="blue-futura">
        Agree to our <a href="/privacy">privacy policy</a>.
      </span>
    </Form.Label>
  </div>
</Form.Group>
</Form>
<div className="container">
  <div className="row homepage-content">
    <div className="col"></div>
    <div className="mt-5 mb-5">
      <div className="download-button">
      <img src={RaysOnly} alt="Background" className="background-image" />
        <Button variant="primary border-0" className="" onClick={sendContact}><span className="button-text">SUBMIT</span></Button>
      </div>
    </div>
    <div className="col"></div>
  </div>
</div>

            </div>
            <div className="col"></div>
        </div>





<FooterText />

     
<div className="footer-image">
  <img src={FooterImage} alt="Footer banner" className="full-width-image" />
</div>

    </>
  );
}

export default Contact;
