import React from 'react';
import './css/About.css';
import {useState , useEffect} from 'react';
// Import ../images/FullLogoStrap.png as Bannerimage
import BannerImage from '../images/FullLogoStrap.png';
import MobileBannerImage from '../images/cleanLogo.png';
import FooterImage from '../images/FooterImage.png';
import PraiseNav from './PraiseNav';
// import LargeAbout from '../images/About Page_Image.png';
import FooterText from './FooterText';
import RaysOnly from '../images/RaysOnly.png';
import Button from 'react-bootstrap/Button';
// Import image Photo montage.jpg
import PhotoMontage from '../images/Photo montage.jpg';


function About() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bannerImage = windowWidth < 850 ? MobileBannerImage : BannerImage;
  return (
    <>
      <div className="gradient-background"></div>
      <div className="navbar-container">
        
     <PraiseNav />


      </div>
      <a href="/" className="text-decoration-none">
      <img src={bannerImage} alt="Praise-along Logo" className="banner-image mt-5 mb-5" />

      </a>

      


        {/* Bootstrap form */}

{/* Testing */}
<div className="row homepage-content">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0" >
     <span className="about-header">
                ABOUT: </span><span className="about-content">
                At Praise-along we write and produce songs especially to help UK primary schools in their provision of collective worship. Our aim is to provide all primary schools with access to high-quality singing resources so that more children can encounter the joy of worship through the unifying power of music.
              </span>
     </div>
     <div className="col " ></div>
   </div>  

{/* End Testing */}


        {/* Testing 2 */}
        <div className="row homepage-content  mt-5">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0" >
     <img src={PhotoMontage} alt="About" className=" mb-5 landing-page-image" />
     <br />
     {/* <span className="about-header">
      
                BACKGROUND </span> */}
                
                
                <span className="about-header">
                OUR VALUES: </span><span className="about-content">
                Our songs are broadly or mainly of a Christian nature, based on values of love, hope and unity and celebrating the breadth of God’s inclusive unconditional love for everyone.
              </span>
              
              </div>
      <div className="col " ></div>
      </div>
      <div className="row homepage-content  mt-5 trans-white-bg ">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0" >
              
              <div style={{width: "100%"}} className=""> <span className="about-header ">
                A COLLECTIVE EFFORT: </span><span className="about-content">
                Our highly skilled team of musicians work in partnership with primary schools to test and record our songs with children.
              </span>
               
              <br/><br/>
              <span className="about-header"><b>SCHOOL PARTNERS:</b></span>
                <br/>
                Wheatley Lane Methodist VA Primary<br/>
Simonstone St Peter’s CofE Primary<br/>
Brunshaw Primary<br/>
St Thomas of Canterbury RC Primary<br/>
Baxenden St John’s CofE Primary<br/>
Le Cateau Primary<br/>
Burnley St Peters CofE Primary<br/>
Hapton CE/Methodist Primary<br/>
Rochdale St Peter’s CofE Primary<br/>
Thornham St John’s CofE Primary<br/>
Milnrow Parish CofE Primary<br/>
</div>
</div>
      <div className="col " ></div>
      </div>
      <div className="row homepage-content  mt-5">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0" >
<span className="about-header">
  
                CHARITY: </span><span className="about-content">
                We regularly support children's charities by helping to raise funds and awareness through our music activities. So far, we have supported:
              </span>
              <br/><br/>
              <a href="https://thefeast.org.uk">The Feast</a><br/>
<a href="https://matrixtrust.com/">The Matrix Trust</a><br/>
<a href="https://www.scottyslittlesoldiers.co.uk/">Scotty’s Little Soldiers</a><br/>
<a href="https://www.facebook.com/readsforrepresentation/">Reads for Representation</a><br/>
<a href="https://www.kaleidoscopic.uk/">Kaleidoscopic UK</a><br/>
<a href="https://mftcharity.org.uk/">The Royal Manchester Children’s Hospital</a><br/>
<a href="https://bacacharity.org.uk/">Baca</a>




     </div>
     <div className="col " ></div>
   </div>  

    {/* End Testing 2 */}
   <div className="row homepage-content mt-5">
    <div className="col"></div>
    <div className="col-10 col-sm-10 col-md-10 col-lg-10 px-0 mt-5 mb-5">
     
     
     <div className="download-button">
        <img src={RaysOnly} alt="Background" className="background-image" />
        <a href="/sing" className="text-decoration-none">

        <Button variant="primary border-0" className=""><span className="button-text">SING!</span></Button>
        </a>
      </div>
    </div>
    <div className="col"></div>
  </div>
   
        
        




<FooterText />

     
<div className="footer-image">
  <img src={FooterImage} alt="Footer" className="full-width-image" />
</div>

    </>
  );
}

export default About;
