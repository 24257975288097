import React from 'react';
import './css/Privacy.css';
import {useState , useEffect} from 'react';
// Import ../images/FullLogoStrap.png as Bannerimage
import BannerImage from '../images/FullLogoStrap.png';
import MobileBannerImage from '../images/cleanLogo.png';
import FooterImage from '../images/FooterImage.png';
import PraiseNav from './PraiseNav';
import FooterText from './FooterText';

function Privacy() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bannerImage = windowWidth < 850 ? MobileBannerImage : BannerImage;
  return (
    <>
      <div className="gradient-background"></div>
      <div className="navbar-container">
        
     <PraiseNav />


      </div>
      <a href="/" className="text-decoration-none">
      <img src={bannerImage} alt="Praise-along Logo" className="banner-image mt-5 mb-5" />

      </a>

      


        {/* Bootstrap form */}
        <div className="row trans-white-bg">
            <div className="col"></div>
            <div className="col-10 col-sm-10 col-md-8 col-lg-6">

              <div className="privacy-header">
                Privacy Notice
              </div>
              <div className="privacy-content">
              Praise-along is a registered trademark of 12 Note Music Limited, a
registered company in England and Wales, number 13395531, Data
Collection Controller ICO Registration No 7B403698
              </div>
              <div className="privacy-subheader">
                WHAT DATA DO WE HOLD?
              </div>
              <div className="privacy-content">
              We hold the information you submit
via our website www.praise-along.com when you register to use our
products and services. This includes the name, email address and
diocese of your school. In order to operate effectively we also collect
statistical information relating to the frequency of your visits to our
website. We will seek your consent to hold this data by using a pop-up
tick box when you register. In the event that you should disclose any
additional personal data in your communications with us, for example,
when offering feedback on our products and services, we will not hold
such data without your explicit permission for our legitimate business
interests and in line with Data Protection laws.We only use industry
recommended third party data management systems with built in
access control and encryption to safeguard any additional data
appropriately.
              </div>
              <div className="privacy-subheader">
                HOW IS IT COLLECTED?
              </div>
              <div className="privacy-content">
              The data is automatically collected from
your computer or device when you register for and use our products
and services. It is then stored on secure data processing systems
located in the UK South region and paired with the UK West region in
compliance with Data Protection law.
              </div>
              <div className="privacy-subheader">
                WHAT WILL IT BE USED FOR?
              </div>
              <div className="privacy-content">
              We have a legitimate interest in
processing your data in relation to our business operations as a music
publishing company:
              </div>
              <div className="privacy-content">
                <ul>
                  <li>To help your school unlock access to our licensed goods and services
online.</li>
                  <li>To contact you with information and updates relating to Praise-along.</li>
                  <li>To help us conduct occasional surveys to help us improve our goods
and services.</li>
                  <li>To help us keep our financial and legal business records up to date.</li>
                  <li>To help us analyse statistical usage of our goods and services by our
customers.</li>
                  <li>To help ensure that the terms of any agreement we have with you
are fulfilled.</li>
                </ul>
              </div>
              <div className="privacy-subheader">
                WITH WHOM WILL IT BE SHARED?
              </div>
              <div className="privacy-content">
              We will not share your data
with any third party, but it may be handled by third parties acting on
our behalf. We will ensure that any such parties comply with Data
Protection laws.
              </div>
              <div className="privacy-subheader">
                HOW CAN I EXERCISE MY RIGHTS OR MAKE A COMPLAINT?
              </div>
              <div className="privacy-content">
              Contact us to withdraw your consent for us to store your data or keep
in touch with you for our business purposes. Please note that we still
may need to hold any data that relates to our legal and tax purposes.
If you have any complaints about the way we have handled your data,
please contact us so that we can seek to address them. Unresolved
complaints can be referred to the <a href="https://ico.org.uk/">UK Information Commissioner</a>.
              </div>
            </div>
            <div className="col"></div>
        </div>








<FooterText />

     
<div className="footer-image">
  <img src={FooterImage} alt="Footer" className="full-width-image" />
</div>

    </>
  );
}

export default Privacy;
