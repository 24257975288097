import React from 'react';
import Button from 'react-bootstrap/Button';
import './css/LandingPage.css';
import {useState , useEffect} from 'react';
// Import ../images/FullLogoStrap.png as Bannerimage
import BannerImage from '../images/FullLogoStrap.png';
import MobileBannerImage from '../images/cleanLogo.png';
import RaysOnly from '../images/RaysOnly.png';
import PraiseNav from './PraiseNav';
import FooterImage from '../images/FooterImage.png';
import FooterText from './FooterText';
function FourOFour() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bannerImage = windowWidth < 850 ? MobileBannerImage : BannerImage;
  return (
    <>
      <div className="gradient-background homescreen-stars-container"></div>
      <div className="navbar-container">
        
      <PraiseNav />
      </div>
      <a href="/" className="text-decoration-none">
      <img src={bannerImage} alt="Praise-along Logo" className="banner-image mt-5 mb-5" />

      </a>

      <div className="row homepage-content">
    <div className="col"></div>
    <div className="col-10 col-sm-10 col-md-10 col-lg-10 px-0 mt-5 mb-5">
     
     <b>Page not found</b>
     <div className="mt-5">
     Please click the link below to access your free monthly song
     </div>
    </div>
    <div className="col"></div>
  </div>
      <div className="container sing-pb">
  <div className="row homepage-content">
    <div className="col"></div>
    <div className="col-10 col-sm-10 col-md-10 col-lg-10 px-0 mt-5 mb-5">
     
     
     <div className="download-button">
        <img src={RaysOnly} alt="Background" className="background-image" />
        <a href="/sing" className="text-decoration-none">

        <Button variant="primary border-0" className=""><span className="button-text">SING!</span></Button>
        </a>
      </div>
    </div>
    <div className="col"></div>
  </div>
</div>


 <div className="container mt-5">

 </div>

<FooterText />

     
<div className="footer-image">
  <img src={FooterImage} alt="Footer" className="full-width-image" />
</div>
    </>
  );
}

export default FourOFour;
