
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import './css/PraiseNav.css';


const PraiseNav = () => {
    return (
        <>
        {[false].map((expand) => (
            <Navbar key={expand}  expand={expand} className="new-praise-along-nav" sticky="top">
              <Container fluid className="d-flex justify-content-between">
              <Navbar.Brand href="/" className="mx-auto">
            <span className="nav-title  red-text-coming-up-nav"><b>UNITING SCHOOLS WITH </b></span><span className="nav-highlight nav-title red-text-coming-up-nav"><b>FREE</b></span> <span className="nav-title red-text-coming-up-nav"><b>MONTHLY SONGS</b></span>
          </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              </Container>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <span className="futura-bold">NAVIGATION</span>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                {/* Breaker line */}
                <hr className="breaker-line"/>
                <Offcanvas.Body>
                  
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <div className="futura-medium-font">

                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/sing">Register</Nav.Link>
                    <Nav.Link href="/comingup">Coming Up</Nav.Link>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                    <Nav.Link href="/privacy">Privacy</Nav.Link>
                    
                    </div>
                  </Nav>
                  
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar>
          ))}
          
        </>
    )
        }
export default PraiseNav;