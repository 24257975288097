import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Privacy from './components/Privacy';
import Admin from './components/Admin';
import ComingUp from './components/ComingUp';
import Sing from './components/Sing';
import About from './components/About';
import Contact from './components/Contact';
import FourOFour from './components/FourOFour';
import './App.css';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/sing" element={<Sing />} />
          <Route path="/comingup" element={<ComingUp />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/ad/admin" element={<Admin />} />
          <Route path="/*" element={<FourOFour />} /> {/* Catch-all route for 404 */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
